<template>
    <ErrorRenderer v-if="capturedError"/>
    <OMediaQueryProvider v-else>
            <OMatchMedia v-slot="{ isMobileOrTablet }">
                <MobileLookup v-if="isMobileOrTablet && !data" v-bind="{ ...props, ...$attrs }" :ref="cpm => mobileLookup=cpm">
                    <template v-for="(_index, name) in $slots" v-slot:[name]="data">
                        <slot :name="name" v-bind="data??{}"/>
                    </template>
                </MobileLookup>
                <DesktopLookup v-else v-bind="$attrs, props" :ref="cmp => desktopLookup=cmp">
                    <template v-for="(_index, name) in $slots" v-slot:[name]="data">
                        <slot :name="name" v-bind="data??{}"/>
                    </template>
                </DesktopLookup>
            </OMatchMedia>
        </OMediaQueryProvider>
</template>

<script>
import DesktopLookup from 'o365.vue.components.DesktopLookup.vue';
import MobileLookup from 'o365.vue.components.MobileLookup.vue';

export default {
    inheritAttrs: false
  }
</script>

<script setup>
import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
import OMatchMedia from 'o365.vue.components.MatchMedia.vue';
import MobileProps from 'o365.controls.MobileLookup.Props.ts';
import DesktopProps from 'o365.controls.DesktopLookup.Props.ts';
import useErrorCapture from 'o365.vue.composables.ErrorCapture.ts';
import { useAttrs } from 'vue';

let mobileLookup = null;
let desktopLookup = null;

const attrs = useAttrs();

if (attrs) {
  if(attrs.onOnbeforeopen) {
    console.warn("@onbeforeopen is deprecated, use @beforeopen");
  }

  if(attrs.onOnbeforeclose) {
    console.warn("@onbeforeclose is deprecated, use @beforeclose");
  }

  if(attrs.onOnopen) {
    console.warn("@onopen is deprecated, use @open");
  }
}

function open() {
    if (desktopLookup?.activateEditor) {
        desktopLookup.activateEditor();
    } else if (mobileLookup?.open) {
        mobileLookup.open();
    }
}

const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render DataLookup`,
    errorRenderFunctionOptions: {
        uiMessage: 'Lookup Render Error',
        uiTitleMessage: 'An error has occurred when rendering this lookup',
        type: 'span'
    }
});

const props = defineProps({...MobileProps, ...DesktopProps});

defineExpose({open});
</script>
